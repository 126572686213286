@use '_vars';

#nlinstitution {
    margin-top: 2em;

    fieldset {
        margin-bottom: 2em;
    }

    fieldset legend {
        font-size: smaller;
        font-weight: bolder;
    }
}

#nlinstitution-licences {
    list-style-image: none;
}

#nlinstitution-licence {
    margin-bottom: 2em;
}

#nlinstitution-licence .btn-danger {
    color: vars.$nl-btn-danger;
}

#nlinstitution-licence #licence-delete {
    margin-top: 2em;
}

#nlinstitution fieldset .lmodel-title {
    margin-bottom: 2em;
}

#nlinstitution button.nl-btn-copy {
    width: 30px;
    margin: 0 0 0 10px;
    padding: 1px 0 1px 0;
}

.access-token {
    margin: 0 0 10px 0;
    padding-left: 25px;
}