@use '_vars';

#nllicence-licences {
    list-style-image: none;
}

#nllicence-licence {
    margin-bottom: 2em;
}

#nllicence-licence .btn-danger {
    color: vars.$nl-btn-danger;
}

#nllicence-licence #licence-delete {
    margin-top: 2em;
}

#nllicence fieldset .lmodel-title {
    margin-bottom: 2em;
}