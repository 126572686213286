@use '_vars';

#NLProductInfo h2 {
	margin: 3em 0 1em 0;
}

#NLProductInfo div.row {
	margin: 0 0 10px 1em;
}

#NLProductInfo .even {
	background-color: #e7f1f6;
}

#NLProductInfo div.nl3-inheritage {
	margin: 20px 20% 0 20%;
	padding: 10px 2% 0 10%;
	border: 1px solid vars.$nl-bg-color-primary;
}

#NLProductInfo div.nl3-inheritage ol {
	margin-top: 0.2em;
	list-style-type: lower-alpha;
}

#NLProductInfo .nl-lmodel-box {
	padding-top: 1em;
	padding-bottom: 1em;
	margin-bottom: 3em;
}

@media screen and (max-width: 768px) {

	#NLProductInfo .col-xs-3,
	#NLProductInfo .col-xs-6 {
		float: none;
	}

	#NLProductInfo div.nl3-inheritage {
		margin: 5px 5% 0 5%;
		padding: 10px 2% 0 2%;
	}

	/* Produktliste */
	#center-and-left-area {
		width: 700px;
	}

}

h3.nl3product-NLLicenceModelStandard,
h3.nl3product-NLLicenceModelOptIn {
	font-size: 12pt;
	font-weight: bolder;
	background: url(/++resource++nl.site/NLInstitution.gif) center left no-repeat;
	padding-left: 20px;
	text-decoration: underline;
}

h3.nl3product-NLLicenceModelSingleUser {
	font-size: 12pt;
	font-weight: bolder;
	background: url(/++resource++nl.site/NLIndividual.gif) center left no-repeat;
	padding-left: 20px;
	text-decoration: underline;
	float: left;
}

#NLProductList {
	margin-top: 20px;
}

#NLProductList li {
	margin-bottom: 0.5em;
}

#NLProductList span.new-product {
	color: #e50000;
	font-weight: bold;
	font-size: 0.8em;
}

div.nl3-status {
	font-size: 0.8em;
}

.pab-container button,
.pab-container button:hover,
.pab-container button:focus {
	border: 1px solid #CBCBCB;
	padding: 2px;
	background-color: vars.$nl-bg-color-primary;
	color: #ffffff;
	cursor: pointer;
	width: 250px;
	text-align: center;
	margin-top: 1em;
}

div.pab-link-acquired {
	background-color: var(--bs-btn-bg);
	border-radius: var(--bs-btn-border-radius);
	color: #ffffff;
	cursor: text;
	margin-top: 1em;
	padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
	text-align: center;
}

div.pab-link-progress {
	background-color: #F6A40E;
	color: #ffffff;
	cursor: text;
	margin-top: 1em;
	padding: 2px;
	width: 250px;
	text-align: center;
}

.nllicence-del img {
	cursor: pointer;
}

div.pab-info {
	margin: 0;
	padding: 1px;
	font-size: 0.8em;
	text-align: left;
	visibility: hidden;
}

#pab-eula-error-msg {
	display: inline;
	visibility: hidden;
}

div.pab-info .col-md-1,
div.pab-info .col-md-11 {
	padding: 5pt 0 0 0;
}

#pab-eula {
	margin-left: 3px;
	border-bottom: 1px solid #000000;
}

#nlproduct-switcher {
	font-size: 1em;
	margin-bottom: 2em;
}

#nlproduct-switcher .next {
	text-align: right;
}

li.plist-already {
	list-style: none;
	list-style-image: url(/@@iconresolver/check2-all);
}

li.plist-fragments {
	list-style: none;
	list-style-image: url(/@@iconresolver/check2);
}

.nl-center {
	text-align: center;
}

.nl-check2-all {
	filter: invert(35%) sepia(99%) saturate(360%) hue-rotate(100deg) brightness(97%) contrast(90%);
}

.nl-check2 {
	filter: invert(56%) sepia(64%) saturate(2424%) hue-rotate(351deg) brightness(100%) contrast(99%);
}
