@use "vars";

#nlregistration p,
#nlregistration div.p {
    margin-top: 2em;
}

#nlregistration .chooser {
    margin-top: 2em;
    padding: 1em 2em 1em 2em;
}

#nlregistration .table {
    border: 1px solid vars.$nl-bg-color-secondary;
    margin-right: 10%;
    width: 40%;
}

#nlregistration .table .row {
    margin-bottom: 2em;
}

#nlregistration .header {
    background-color: vars.$nl-bg-color-primary;
    padding: 10px;
    vertical-align: top;
    color: white;
}

#nlregistration .portalMessage {
    margin-top: 2em;
}

#nlregistration li {
    font-weight: normal;
}

#nlregistration .list-label {
    font-weight: bold;
}

#nlregistration.info-form input[type="text"],
#nlregistration.info-form textarea {
    width: 40%;
}

#nlregistration fieldset {
    margin-bottom: 2em;
    width: 100%;
}

#nl-privacy {
    margin-bottom: 4em;
}

#nl-privacy .emphasis {
    margin-bottom: 2em;
}

@media screen and (max-width: 768px) {
    #nlregistration .table {
        border: 1px solid vars.$nl-bg-color-secondary;
        margin-right: 0;
        margin-bottom: 2em;
        width: 90%;
    }

    #nlregistration.info-form input[type="text"],
    #nlregistration.info-form textarea {
        width: 90%;
    }

}