@use 'vars';

#nlindividual {
    margin-top: 2em;

    fieldset {
        margin-bottom: 2em;
    }

    fieldset legend {
        font-size: smaller;
        font-weight: bolder;
    }
}

#nlindividual-licences {
    list-style-image: none;
}

#nlindividual-licence {
    margin-top: 3em;
}

#nlindividual-licence .btn-danger {
    color: vars.$nl-btn-danger;
}

#nlindividual-licence #licence-delete {
    margin-top: 2em;
}

#nl-ind-remove {
    margin-top: 2em;
}

#nl-ind-msg {
    display: none;
    margin-top: 20px;
}

#nl_prolong_action,
#nl_renew_action {
    margin-top: 20px;
    text-align: center;
}

#nl_prolong_action a,
#nl_renew_action a {
    border-bottom: 1px solid black;
}

/* #nlindividual .even {
	background-color: #dce6eb;
} */